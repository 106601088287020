.fade {
  @include transition($transition-fade);

  &:not(.show) {
    // opacity: 0;
    display: none;
  }
}

// scss-docs-start collapse-classes
.collapse {
  &:not(.show) {
    display: none;
  }
}

.collapsing {
  height: 0;
  overflow: hidden;
  @include transition($transition-collapse);
}
// scss-docs-end collapse-classes
